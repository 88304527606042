<template>
  <div>
    <h2 class="gavc-h3">Données d'identification des parties</h2>
    <p
      class="gavc-chapo"
    >
      Dans cette étape, vérifiez les informations relatives aux deux parties (cédant et repreneur) en déroulant chacune des sections.
    </p>
    <b-button
      class="mt-3 subsection-button"
      block
      v-b-toggle.cedant-collapse
    >
      Le cédant
      <span class="when-opened float-right">
        <v-icon name="chevron-down" />
      </span>
      <span class="when-closed float-right">
        <v-icon name="chevron-right" />
      </span>
    </b-button>

    <b-collapse id="cedant-collapse" role="tabpanel">
      <CedantItem
        :cedant="pourparler.candidature.offre_cession.entreprise_cedee.cedant"
      />
    </b-collapse>


    <div v-if="!pourparler.candidature.offre_cession.entreprise_cedee.cedant.is_personne_physique">
      <b-button
        class="mt-3 subsection-button"
        block
        v-b-toggle.cedant-company-collapse
      >
        La société du cédant détenant l'entreprise cédée
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="cedant-company-collapse" role="tabpanel">
        <CompanyItem
          :company="pourparler.candidature.offre_cession.entreprise_cedee.cedant.company"
        />
      </b-collapse>
    </div>

    <b-button
      class="mt-3 subsection-button"
      block
      href="#"
      v-b-toggle.cedant-entreprise-cedee-collapse
    >
      L'entreprise cédée
      <span class="when-opened float-right">
        <v-icon name="chevron-down" />
      </span>
      <span class="when-closed float-right">
        <v-icon name="chevron-right" />
      </span>
    </b-button>

    <b-collapse id="cedant-entreprise-cedee-collapse" role="tabpanel">
      <CompanyItem
        :company="pourparler.candidature.offre_cession.entreprise_cedee"
      />
    </b-collapse>

    <b-button
      class="mt-3 subsection-button"
      block
      href="#"
      v-b-toggle.repreneur-collapse
    >
      Le repreneur
      <span class="when-opened float-right">
        <v-icon name="chevron-down" />
      </span>
      <span class="when-closed float-right">
        <v-icon name="chevron-right" />
      </span>
    </b-button>

    <b-collapse id="repreneur-collapse" role="tabpanel">
      <RepreneurItem :repreneur="pourparler.candidature.repreneur" />
    </b-collapse>

    <div v-if="!pourparler.candidature.repreneur.is_personne_physique">
      <b-button
        class="mt-3 subsection-button"
        block
        href="#"
        v-b-toggle.repreneur-company-collapse
      >
        L'entreprise du repreneur
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>

      <b-collapse id="repreneur-company-collapse" role="tabpanel">
        <CompanyItem :company="pourparler.candidature.repreneur.company" />
      </b-collapse>
    </div>

    <div
      class="row mt-4"
    >
      <div class="col-12 text-right">
        <button
          v-if="
            (userGroupContains(['CEDANT']) &&
              !pourparler.cedant_has_validated_identification_parties) ||
            (userGroupContains(['REPRENEUR']) &&
              !pourparler.repreneur_has_validated_identification_parties)
          "
          class="mt-2 ml-2 gavc-btn"
          @click="$emit('validatePourparler')"
        >
          Valider l'étape d'identification des parties
        </button>
        <button
          v-else-if="!pourparler.has_cedant_validated || !pourparler.has_repreneur_validated"
          class="mt-2 ml-2 gavc-btn gavc-btn--bone"
          @click="$emit('unvalidatePourparler')"
        >
          Invalider l'étape d'identification des parties
        </button>
        <button
          v-if="
            pourparler.cedant_has_validated_identification_parties &&
            pourparler.repreneur_has_validated_identification_parties
          "
          class="mt-2 ml-2 gavc-btn"
          @click="$emit('nextStep')"
        >
          Etape suivante
        </button>
        <button
          v-else-if="
            (userGroupContains(['CEDANT']) &&
              pourparler.cedant_has_validated_identification_parties) ||
            (userGroupContains(['REPRENEUR']) &&
              pourparler.repreneur_has_validated_identification_parties)
          "
          class="mt-2 ml-2 gavc-btn"
          :disabled="true"
        >
          En attente de validation des deux parties
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import userMixins from "@/mixins/userMixins";

const CedantItem = () => import("@/components/cedant/cedantItem");
const CompanyItem = () => import("@/components/company/companyItem");
const RepreneurItem = () => import("@/components/repreneur/repreneurItem");


export default {
  name: 'pourparlerIdentificationParties',
  components: {
    CedantItem,
    CompanyItem,
    RepreneurItem,
  },
  mixins: [userMixins],
  props: {
    pourparler: {
      type: Object
    }
  }
}
</script>
